import React, { ReactNode, useEffect, useState } from "react";
import { Button, TextareaField } from "@shoptet/ui";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  useLastImageSuggestionRequestsQuery,
  useRequestNewImageSuggestionMutation,
  useRequestNewSuggestionMutation,
} from "../../graphql/generated/graphql";
import { Accordion } from "../Accordion";
import { PrimaryColor } from "../AdvancedSetup/PrimaryColor";
import { CategorySelectionWrapper } from "../CategoriesSelection";
import { NextIcon } from "../Icons";
import { InfoBox, WarningBox } from "../InfoBox";

type SuggestionInputFormPropsT = {
  category?: string | null;
  children?: ReactNode;
  continueToNextStepText?: string;
  defaultUserInput?: string;
  disabled?: boolean;
  hideCategories?: boolean;
  onComplete?: () => void;
  onStart?: () => void;
  refetchSuggestions?: () => void;
};

const MINIMUM_LENGTH = 350;
const MAXIMUM_LENGTH = 1000;

export const SuggestionInputForm = ({
  category,
  children,
  continueToNextStepText,
  defaultUserInput,
  disabled,
  hideCategories,
  onComplete,
  onStart,
  refetchSuggestions,
}: SuggestionInputFormPropsT) => {
  const { t } = useTranslation("translation", { keyPrefix: "Wizard Suggestion Generator Page" });
  const [userInput, setUserInput] = useState(defaultUserInput || "");
  const [areRequestsSent, setRequestsSent] = useState(false);
  const [sendingRequests, setSendingRequests] = useState(false);

  const { startPolling, stopPolling } = useLastImageSuggestionRequestsQuery({
    skip: !areRequestsSent,
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      if (!response.organization?.lastImageSuggestionRequests?.[0].inProgress) {
        if (typeof onComplete === "function") {
          stopPolling();
          setTimeout(() => stopPolling(), 1200);
          onComplete();
        }
      }
    },
  });

  useEffect(() => {
    return () => stopPolling();
  }, [stopPolling]);

  const [requestSuggestion, {}] = useRequestNewSuggestionMutation({
    onCompleted() {
      // toast.success("Započali jsme pro vás tvořit návrhy");
      if (typeof refetchSuggestions === "function") {
        refetchSuggestions();
      } else {
        setRequestsSent(true);
        setTimeout(() => startPolling(1000), 1000);
      }
      setSendingRequests(false);
    },
  });

  const [requestImageSuggestion, {}] = useRequestNewImageSuggestionMutation({
    variables: {},
    onCompleted: () => {
      requestSuggestion({ variables: { userInput: userInput.replace(/\s+/g, " ") } });
    },
  });

  const userInputLength = userInput.trim().replace(/\s+/g, " ").length;
  const disabledActions = disabled || sendingRequests || areRequestsSent;
  const invalidLength = userInputLength < MINIMUM_LENGTH || userInputLength > MAXIMUM_LENGTH;
  const disabledSubmit = disabledActions || invalidLength;
  return (
    <div>
      <div className="v2form v2form--alignStart">
        <TextareaField
          disabled={disabledActions}
          id="suggestions_text"
          label={t("textarea label")}
          name="suggestions_text"
          rows={10}
          style={{ width: "1000px" }}
          tooltip={t("textarea tooltip")}
          value={userInput}
          onChange={(event) => setUserInput(event.target.value)}
        />
        <div style={{ marginLeft: "calc(min(340px, 30vw) + 4px)" }}>
          <div style={{ color: invalidLength ? "#db1b4e" : "#9DCA57" }}>
            {t(userInputLength > MAXIMUM_LENGTH ? "character counter message over" : "character counter message", {
              minimumLength: MINIMUM_LENGTH,
              maximumLength: MAXIMUM_LENGTH,
              userInputLength,
            })}
          </div>

          <p className="SuggestionExampletText" style={{ color: "#565656" }}>
            <strong>{t("example")}</strong> {t(`example text by category.${category || "UNKNOWN"}`)}
          </p>
        </div>
      </div>
      {!hideCategories && (
        <Accordion header={t("Advanced options")}>
          <WarningBox>{t("Advanced options info box")}</WarningBox>
          <h3>{t("Selected categories headline")}</h3>
          <p>{t("Selected categories description in wizard")}</p>
          <CategorySelectionWrapper disabled={disabledActions} />

          <h3>{t("Primary Color headline")}</h3>
          <p>{t("Primary Color description")}</p>
          <PrimaryColor />
        </Accordion>
      )}
      <br />
      <br />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {children}
        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
          {userInputLength < 350 && (
            <span style={{ color: "#db1b4e", marginRight: "20px" }}>{t("Missing information")}</span>
          )}

          {!onComplete && areRequestsSent ? (
            <InfoBox>
              <Link to="/assets">{t("Checkout assets")}</Link>
            </InfoBox>
          ) : (
            <Button
              data-test-id="continue-to-next-step"
              disabled={disabledSubmit}
              onClick={() => {
                if (typeof onStart === "function") {
                  onStart();
                }
                setSendingRequests(true);
                requestImageSuggestion();
              }}
            >
              {continueToNextStepText || (
                <span style={{ display: "flex", alignItems: "center" }}>
                  {t("Continue to next step")}
                  &nbsp;
                  <NextIcon />
                </span>
              )}
            </Button>
          )}
        </div>
      </div>
      {hideCategories && (
        <div style={{ marginTop: "32px" }}>
          <h3>{t("Primary Color headline")}</h3>
          <p>{t("Primary Color description")}</p>
          <PrimaryColor />
        </div>
      )}
    </div>
  );
};
